import React, { useState, useEffect } from "react";
import PlanService from "../services/plan.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import commonFunctions from "../common/Functions";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import { getBranches } from "../common/getFunctions";
import { Card, Row, Col } from "react-bootstrap";
import PlanTypes, { planTypes } from "../commonComponents/PlanTypes";
import BranchePullDown from "../commonComponents/BranchePullDown";
const currentUser = JSON.parse(localStorage.getItem("user"));
const Plans = () => {
  const [error, setError] = useState("");
  const [plans, setPlans] = useState([]);
  const [branchFilter, setBranchFilter] = useState();
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [planFilter, setPlanFilter] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const pageSizes = [9, 18, 24, 30, 36, 60];

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      await listPlans("", "", page, itemsPerPage);
    }

    onReady();
  }, []);

  /**
   *
   * @param {id} branchFilter
   * @param {string} planFilter
   * @param {number} page
   * @param {number} itemsPerPage
   */
  const listPlans = async (branchFilter, planFilter, page, itemsPerPage) => {
    setLoading(true);
    PlanService.getPlansList(
      branchFilter,
      planFilter,
      parseInt(page - 1) * itemsPerPage,
      itemsPerPage,
      false
    ).then(
      (response) => {
        let data = response.data.data;
        setPlans(data.list);
        setTotal(data ? data.total : 0);
        setCount(Math.ceil(data.total / itemsPerPage));
        setResetMsg("");
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setError(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
    await listPlans(branchFilter, planFilter, value, itemsPerPage);
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listPlans(branchFilter, planFilter, 1, newItemsPerPage);
  };
  const resetSearch = async () => {
    setBranchFilter("");
    setPlanFilter("");
    await listPlans("", "", page, itemsPerPage);
  };
  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listPlans(branchFilter, planFilter, 1, itemsPerPage);
  };
  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    resetResults();
  };
  const handlePlanChange = async (planType) => {
    setPlanFilter(planType);
    resetResults();
  };
  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setPlans([]);
    setResetMsg(labels.RESET_MSG);
  };

  const deletePlan = async (event, id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this plan? \n Make sure no subscriptions are created for this plan!"
      )
    ) {
      PlanService.deletePlan(id).then(
        (response) => {
          alert("Plan deleted!");
          let oldList = plans;
          var data = oldList.filter(function (obj) {
            return obj._id !== id;
          });
          setPlans(data);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  return (
    <div className="container">
      {currentUser.type == "admin" ? (
        <div className="floatRight ">
          <NavLink to={"/plan/new"} className="greenLink mx-0">
            Add Plan
          </NavLink>
        </div>
      ) : (
        ""
      )}
      <h3> Plans List </h3>
      <div className="filterContainer">
        {currentUser && currentUser.type == "admin" ? (
          <Col md={3}>
            <label className="filterLabelInput" htmlFor="branch">
              Branch
            </label>

            <BranchePullDown
              branch={branchFilter}
              addFormGroup="no"
              labelInside={true}
              forceBlank={true}
              setBranch={handleBranchChange}
              branches={branches}
            />
          </Col>
        ) : null}
        <Col md={3}>
          <label className="filterLabelInput" htmlFor="branch">
            Plan Type
          </label>
          <PlanTypes
            name="planTypeFilter"
            addFormGroup="no"
            labelInside={true}
            forceBlank={true}
            defaultValue={planFilter}
            setValue={handlePlanChange}
          />
        </Col>
        <Col md={3} style={{ marginTop: 40 }}>
          <table border="0">
            <tr>
              <td>
                <button
                  onClick={(e) => triggerSearch(e)}
                  className="btn btn-primary btn-block search-button "
                >
                  Go
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary btn-block reset-button "
                >
                  Reset
                </button>
              </td>
            </tr>
          </table>
        </Col>
      </div>

      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}
      {!error && !loading && plans && plans.length != 0 ? (
        <div>
          <Row>
            <Col>
              <span style={{ float: "right", marginLeft: 10 }}>
                {total} results
              </span>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "first baseline",
            }}
          >
            {plans.map((b) => (
              <div style={{ float: "left", marginLeft: 20 }}>
                <Card
                  style={{
                    marginLeft: 0,
                    maxWidth: 250,
                    minHeight: 300,
                    paddingLeft: 10,
                    align: "top",
                  }}
                >
                  <Card.Body>
                    <Card.Title>{b.label}</Card.Title>
                    <NavLink
                      to={"/plan/" + b._id}
                      className="text-dark midText"
                    >
                      <i
                        alt="View"
                        title="View"
                        className="fa fa-binoculars"
                      ></i>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;
                    <NavLink
                      to={"/plan/edit/" + b._id}
                      className="text-dark midText"
                    >
                      <i alt="Edit" title="Edit" className="fa fa-pen"></i>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;
                    <NavLink
                      to={"/plan/clone/" + b._id}
                      className="text-dark midText"
                    >
                      <i alt="Clone" title="Clone" className="fa fa-clone"></i>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;
                    <a
                      href="javascript:void(0)"
                      className="text-dark midText"
                      onClick={(e) => deletePlan(e, b._id)}
                    >
                      <i
                        alt="Delete"
                        title="Delete"
                        className="fa fa-trash"
                      ></i>
                    </a>
                    <br />
                    <span className="tinyCardLabel">Disabled:&nbsp;</span>
                    <span className="midText">
                      {b.disabled ? "Disabled" : "Active"}
                    </span>
                    <br />
                    <span className="tinyCardLabel">Internal:&nbsp;</span>
                    <span className="midText">{b.internal ? "Yes" : "No"}</span>
                    <br />
                    <span className="tinyCardLabel">Chargers Count:&nbsp;</span>
                    <span className="midText">{b.chargersCount || 1}</span>
                    <br />
                    <span className="tinyCardLabel">
                      Batteries Count:&nbsp;
                    </span>
                    <span className="midText">{b.batteriesCount || 1}</span>
                    <br />
                    <span className="tinyCardLabel">Period:&nbsp;</span>
                    <span className="midText">
                      {b.period}{" "}
                      {b.planType === "daily"
                        ? "day"
                        : b.planType === "hourly"
                        ? "hour"
                        : b.planType === "weekly"
                        ? "week"
                        : "month"}
                      {b.period > 1 ? "s" : ""}
                    </span>
                    <br />
                    <span className="tinyCardLabel">Unit Price:&nbsp;</span>
                    <span className="midText">
                      {Number(b.price).toFixed(2)} {b.currency}
                    </span>
                    <br />
                    <span className="tinyCardLabel">VAT:&nbsp;</span>
                    <span className="midText">{b.VAT}</span>
                    <br />
                    <span className="tinyCardLabel">Total:&nbsp;</span>
                    <span className="midText">
                      {commonFunctions.CommaFormatted(
                        Math.round(b.price * (1 + b.VAT / 100))
                      )}{" "}
                      {b.currency}
                    </span>
                    <br />
                    <span className="tinyCardLabel">Surcharge:&nbsp;</span>
                    <span className="midText">
                      {b.activateInvoiceSurcharge ? "Active" : "Disabled"}
                    </span>
                    <br />
                    {currentUser.type === "admin" ? (
                      <>
                        <span className="tinyCardLabel">Branch:&nbsp;</span>
                        <span className="midText">{b.branch.label}</span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Row>

          <div className="mt-3">
            {"Items per Page: "}
            <select
              onChange={(e) => handlePageSizeChange(e.target.value)}
              value={itemsPerPage}
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingtotal={1}
              boundarytotal={1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={handlePageChange}
              size="medium"
            />
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : resetMsg != "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
    </div>
  );
};

export default Plans;
