function DateFormatterOne(oldDate) {
  if (!oldDate || oldDate == "") return "";

  // const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  try {
    let d = oldDate.split("T")[0].split("-");
    let time = new Date(d[0], d[1] - 1, d[2]);
    let day = time.getDate();

    return `${months[time.getMonth()]} ${day}, ${time.getFullYear()}`;
    // Date Example: May 22, 1996
  } catch (error) {
    return "";
  }
}

function DateFormatterTwo(oldDate) {
  if (!oldDate || oldDate == "") return "";

  try {
    let d = new Date(oldDate);
    return `${d.getDate()}/${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${d.getFullYear()}`;
    // Date Example: 22/03/1995
  } catch (error) {
    return "";
  }
}

function DateFormatterThree(oldDate) {
  if (!oldDate || oldDate == "") return "";

  try {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let d = new Date(oldDate);
    return d.getDate() + "-" + months[d.getMonth()] + "-" + d.getFullYear();
    // Date Example: 22/03/1995
  } catch (error) {
    return "";
  }
}

function beautifyDate(oldDate) {
  //it takes a string as follows
  //Mon Oct 03 2022 09:00:00
  //returns Oct 03, 2022

  if (!oldDate || oldDate === "") return "";

  try {
    let d = oldDate.split(" ");
    return `${d[1]} ${d[2]}, ${d[3]}`;
  } catch (error) {
    return "";
  }
}
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  var month = result.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  var day = result.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  return result.getFullYear() + "-" + month + "-" + day;
}

// This function get the end date with respect to current Date
export const getStartEndDate = (period) => {
  var date = new Date();

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  if (month < 10) month = "0" + month;

  if (day < 10) day = "0" + day;

  var now = year + "-" + month + "-" + day;

  return { start: now, end: addDays(date, period) };
};

// This function get the end date with respect to client input (start Date)
function getEndDate(startDate, period, periodicString = "month") {
  let endDate;

  switch (periodicString) {
    case "week":
      endDate = new Date(
        startDate.getTime() + 7 * 24 * 60 * 60 * 1000 * period
      ); // Add period * 7  days
      break;
    case "day":
      endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000 * period); // Add period *1 day
      break;
    case "hour":
      endDate = new Date(startDate.getTime() + 60 * 60 * 1000 * period); // Add period  * 1 hour
      break;

    default:
      var dt = new Date(startDate);
      dt.setMonth(dt.getMonth() + parseInt(period));
      dt.setDate(dt.getDate() - 1);
      var year = dt.getFullYear();
      var month = parseInt(dt.getMonth() + 1);
      var day = dt.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }
      endDate = year + "-" + month + "-" + day;

      break;
  }
  return endDate;
}
// This function get the Current Date.
function getCurrentDate() {
  var date = new Date();

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  if (month < 10) month = "0" + month;

  if (day < 10) day = "0" + day;

  return year + "-" + month + "-" + day;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function convertMsToTime(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
}

function tConvert(time) {
  // Check correct time format and split into components
  if (time && time.split(" ")[4]) {
    time = time.split(" ")[4].slice(0, 5);
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  } else return time;
}

function fullNiceDate(d) {
  const date = new Date(d);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  var mS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    mS[date.getMonth()] +
    " " +
    date.getDate() +
    " " +
    date.getFullYear() +
    " at " +
    strTime
  );
}

function getSimpleDate(date) {
  var currentDate = new Date();
  var date = new Date(date);

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hours = currentDate.getHours();
  var minutes = currentDate.getMinutes();
  var seconds = currentDate.getSeconds();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;
  if (hours < 10) hours = "0" + hours;

  console.log(year + "-" + month + "-" + day);
  return (
    year +
    "-" +
    month +
    "-" +
    day +
    "T" +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    ".000Z"
  );
}
export default {
  fullNiceDate,
  DateFormatterOne,
  DateFormatterTwo,
  getAge,
  getStartEndDate,
  addDays,
  getCurrentDate,
  getEndDate,
  DateFormatterThree,
  padTo2Digits,
  convertMsToTime,
  tConvert,
  beautifyDate,
  getSimpleDate,
};
