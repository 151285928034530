import React, { useState, useEffect } from "react";
import authHeader from "../services/auth-header";
import DatePicker from "react-datepicker";
import cashLogservice from "../services/cashLog.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import {
  getBranches,
  getBranchAccounts,
  getCashlogCategories,
} from "../common/getFunctions";
import BranchePullDown from "../commonComponents/BranchePullDown";
import { Card, Row, Col } from "react-bootstrap";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";
import DateFormatter from "../common/DateFromatter";
import commonFunctions from "../common/Functions";
import PullDown from "../commonComponents/PullDown";

const currentUser = JSON.parse(localStorage.getItem("user"));
const CashLogs = (props) => {
  const invoiceId = props.invoiceId || "";
  const isView = props.isView || false;
  const readyFunction = props.readyFunction || null;

  const [error, setError] = useState("");
  const [cashLogs, setCashLogs] = useState([]);
  const [branchFilter, setBranchFilter] = useState(currentUser.branch);
  const [branches, setBranches] = useState([]);
  const [accountHash, setAccountHash] = useState([]);
  const [accountFilter, setAccountFilter] = useState();
  const [branchAccounts, setBranchAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [keyword, setkeyword] = useState("");
  const [accountType, setAccountType] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [logsPwd, setLogsPwd] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const pageSizes = [9, 18, 24, 30, 36, 60];

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      let defaultAccount = "";
      let accountTypeTemp = "";
      if (!isView) {
        setBranches(await getBranches());
        const accounts = await getBranchAccounts(currentUser.branch);

        setBranchAccounts(accounts);
        triggerAccountHash(accounts);
        setCategories(await getCashlogCategories(currentUser.branch));
        if (
          currentUser.type !== "registered" &&
          currentUser.type !== "subscriber" &&
          currentUser.type !== "accountant"
        ) {
          const defaultAccountObj = accounts.find(
            (acc) => acc.type === "cash" && acc.currency === "USD"
          );
          defaultAccount = defaultAccountObj?.key || "";
          accountTypeTemp = defaultAccountObj?.type || "";
          setAccountType(accountTypeTemp);
          setAccountFilter(defaultAccount);
        }
        setCategoryFilter("");
      } else {
        readyFunction();
      }

      await listCashLogs(
        "",
        "",
        invoiceId,
        page,
        itemsPerPage,
        defaultAccount,
        "",
        "",
        accountTypeTemp,
        ""
      );
    }

    onReady();
  }, []);

  const listCashLogs = async (
    branchFilter,
    keyword,
    invoiceId,
    page,
    itemsPerPage,
    accountFilter,
    categoryFilter,
    pwd,
    accountType,
    transactionDate
  ) => {
    // only allow those of type cash.

    if (
      pwd === "" &&
      accountType != "cash" &&
      currentUser.type !== "registered" &&
      currentUser.type !== "subscriber" &&
      currentUser.type !== "accountant"
    ) {
      setResetMsg(labels.CONFIDENTIAL_INFO);
      setLoading(false);
      return;
    }
    setLoading(true);
    cashLogservice
      .getCashLogsList(
        branchFilter,
        keyword,
        invoiceId,
        parseInt(page - 1) * itemsPerPage,
        itemsPerPage,
        false,
        accountFilter,
        categoryFilter,
        pwd,
        accountType,
        transactionDate
      )
      .then(
        (response) => {
          let data = response.data.data;
          setCashLogs(data.list);
          setTotal(data ? data.total : 0);
          setCount(Math.ceil(data.total / itemsPerPage));
          setResetMsg("");
          setLoading(false);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          setResetMsg(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
    await listCashLogs(
      branchFilter,
      keyword,
      invoiceId,
      value,
      itemsPerPage,
      accountFilter,
      categoryFilter,
      logsPwd,
      accountType,
      transactionDate
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listCashLogs(
      branchFilter,
      keyword,
      invoiceId,
      1,
      newItemsPerPage,
      accountFilter,
      categoryFilter,
      logsPwd,
      accountType,
      transactionDate
    );
  };

  const resetSearch = async () => {
    setBranchFilter(currentUser.branch);
    setAccountFilter("");
    setCategoryFilter("");
    setAccountType("");
    setCashLogs([]);
    setTransactionDate("");
    await listCashLogs(
      currentUser.branch,
      "",
      invoiceId,
      page,
      itemsPerPage,
      "",
      "",
      logsPwd,
      "",
      ""
    );
  };
  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listCashLogs(
      branchFilter,
      keyword,
      invoiceId,
      1,
      itemsPerPage,
      accountFilter,
      categoryFilter,
      logsPwd,
      accountType,
      transactionDate
    );
  };

  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    setCategories(await getCashlogCategories(bid));
    const accounts = await getBranchAccounts(bid);
    setBranchAccounts(accounts);
    triggerAccountHash(accounts);
    resetResults();
  };

  const handleAccountChange = async (id) => {
    setAccountFilter(id);
    const selectedAccount = branchAccounts.find((acc) => acc.key === id);
    setAccountType(selectedAccount?.type || "");
    resetResults();
  };

  const handleCategoryChange = async (id) => {
    setCategoryFilter(id);
    resetResults();
  };

  const handleKeywordChange = (val) => {
    setkeyword(val);
    resetResults();
  };

  const handlePwdChanged = (val) => {
    setLogsPwd(val);
    resetResults();
  };

  const handleTransactionDate = (val) => {
    setTransactionDate(val);
    resetResults();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };
  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setCashLogs([]);
    setResetMsg(labels.RESET_MSG);
  };
  const triggerAccountHash = (accounts) => {
    const temp = [];
    for (const a of accounts) {
      temp[a.key] = a.label;
    }
    setAccountHash(temp);
  };
  const deleteCashLog = async (event, id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this log? \n Make sure no packages are created for this cashLog!"
      )
    ) {
      cashLogservice.deleteCashLog(id).then(
        (response) => {
          alert("CashLog deleted!");
          let oldList = cashLogs;
          var data = oldList.filter(function (obj) {
            return obj._id !== id;
          });
          setCashLogs(data);
          setTotal(total - 1);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  return (
    <>
      <div className="container">
        {!isView ? (
          <>
            <h3> Logs List </h3>
            <Row style={{ display: "flex", flexDirection: "row" }}>
              {currentUser && currentUser.type == "admin" ? (
                <Col md={3}>
                  <BranchePullDown
                    addFormGroup="no"
                    extraClassLabel="filterLabelInput maintenanceLabels"
                    branch={branchFilter}
                    labelInside={false}
                    forceBlank={false}
                    setBranch={handleBranchChange}
                    branches={branches}
                  />
                </Col>
              ) : null}

              <Col md={3}>
                <label
                  className="filterLabelInput maintenanceLabels"
                  htmlFor="accountFilter"
                >
                  Account
                </label>
                <PullDown
                  defaultValue={accountFilter}
                  labelInside={false}
                  pulldownFor={"accountFilter"}
                  forceBlank={true}
                  setValue={handleAccountChange}
                  options={branchAccounts}
                />
              </Col>
              <Col md={3}>
                <label
                  className="filterLabelInput maintenanceLabels"
                  htmlFor="categoryFilter"
                >
                  Category
                </label>
                <PullDown
                  defaultValue={categoryFilter}
                  labelInside={false}
                  pulldownFor={"categoryFilter"}
                  forceBlank={true}
                  setValue={handleCategoryChange}
                  options={categories}
                />
              </Col>
              <Col md={3}>
                <label
                  className="filterLabelInput maintenanceLabels"
                  htmlFor="keyword"
                >
                  Keyword
                </label>
                <input
                  autoComplete="new-password"
                  type="text"
                  className="form-control filterInput2"
                  name="keyword"
                  value={keyword}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) => handleKeywordChange(e.target.value)}
                />
              </Col>
              <Col md={3}>
                <label
                  className="filterLabelInput maintenanceLabels"
                  htmlFor="transactionDate"
                >
                  Date
                </label>
                <DatePicker
                  autoComplete="new-password"
                  dateFormat="dd-MM-yyyy"
                  className="form-control filterInput2"
                  name="transactionDate"
                  selected={transactionDate}
                  onChange={(date) => handleTransactionDate(date)}
                />
              </Col>
              {currentUser.type !== "accountant" && (
                <Col md={3}>
                  <label
                    className="filterLabelInput maintenanceLabels"
                    htmlFor="logsPwd"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control filterInput2"
                    name="logsPwd"
                    value={logsPwd}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => handlePwdChanged(e.target.value)}
                  />
                </Col>
              )}
              <Col style={{ marginTop: 40 }} md={2}>
                <table border="0">
                  <tr>
                    <td>
                      <button
                        onClick={(e) => triggerSearch(e)}
                        className="btn btn-primary btn-block search-button "
                      >
                        Go
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={(e) => resetSearch(e)}
                        className="btn btn-primary btn-block reset-button "
                      >
                        Reset
                      </button>
                    </td>
                  </tr>
                </table>
              </Col>
            </Row>
          </>
        ) : null}

        {error ? (
          <header className="jumbotron">
            {" "}
            <h3>{error}</h3>{" "}
          </header>
        ) : null}
        {!error && !loading && cashLogs && cashLogs.length != 0 ? (
          <div>
            <Row>
              <Col>
                <span style={{ float: "right", marginLeft: 10 }}>
                  {total} results
                </span>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "self-start",
              }}
            >
              {cashLogs.map((c) => (
                <div style={{ float: "left", marginLeft: 20 }}>
                  <Card
                    style={{
                      marginLeft: 0,
                      maxWidth: 250,
                      minHeight: isView ? 100 : 300,
                      paddingLeft: 10,
                      align: "top",
                    }}
                  >
                    <Card.Body>
                      {!isView ? (
                        <InternalNoteComponent
                          clickable={false}
                          note={c.notes}
                        />
                      ) : null}
                      {!isView ? <Card.Title>{c.label}</Card.Title> : null}
                      {!c.invoice && currentUser.type !== "accountant" ? (
                        <>
                          <NavLink
                            to={"/cashlog/" + c._id}
                            className="text-dark midText"
                          >
                            <i
                              alt="View"
                              title="View"
                              className="fa fa-binoculars"
                            ></i>
                          </NavLink>
                          &nbsp;&nbsp;&nbsp;
                          <NavLink
                            to={"/cashlog/edit/" + c._id}
                            className="text-dark midText"
                          >
                            <i
                              alt="Edit"
                              title="Edit"
                              className="fa fa-pen"
                            ></i>
                          </NavLink>
                          &nbsp;&nbsp;&nbsp;
                          <NavLink
                            to={"/cashlog/clone/" + c._id}
                            className="text-dark midText"
                          >
                            <i
                              alt="Clone"
                              title="Clone"
                              className="fa fa-clone"
                            ></i>
                          </NavLink>
                          &nbsp;&nbsp;&nbsp;
                          <a
                            href="javascript:void(0)"
                            className="text-dark midText"
                            onClick={(e) => deleteCashLog(e, c._id)}
                          >
                            <i
                              alt="Delete"
                              title="Delete"
                              className="fa fa-trash"
                            ></i>
                          </a>
                          <br />
                        </>
                      ) : null}
                      {c.number ? (
                        <>
                          <span className="tinyCardLabel">Number:&nbsp;</span>
                          <span className="midText">{c.number}</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <span className="tinyCardLabel">Amount:&nbsp;</span>
                      <span className="midText">
                        {commonFunctions.CommaFormatted(c.amount)} {c.currency}
                      </span>
                      <br />
                      <span className="tinyCardLabel">Discount:&nbsp;</span>
                      <span className="midText">{c.discount} USD</span>
                      <br />
                      <span className="tinyCardLabel">Category:&nbsp;</span>
                      <span className="midText">
                        {c.categoryId ? c.categoryId.label : "None"}
                      </span>
                      <br />

                      <span className="tinyCardLabel">
                        Transaction Date:&nbsp;
                      </span>
                      <span className="midText">
                        {DateFormatter.DateFormatterOne(c.cashDate)}
                      </span>
                      <br />

                      {!isView ? (
                        <>
                          <span className="tinyCardLabel">Receipt:&nbsp;</span>
                          <span className="midText">
                            {c.receipt ? "Yes" : "No"}
                          </span>
                          <br />
                          {c.invoice ? (
                            <>
                              <span className="tinyCardLabel">
                                Invoice:&nbsp;
                              </span>
                              <span className="midText">
                                <NavLink
                                  className="text-dark"
                                  target="_blank"
                                  to={"/invoice/" + c.invoice._id}
                                >
                                  {c.invoice.number}
                                </NavLink>
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          {c.biker ? (
                            <>
                              <span className="tinyCardLabel">
                                Biker:&nbsp;
                              </span>
                              <span className="midText">
                                {c.biker.full_name}
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          {c.sender ? (
                            <>
                              <span className="tinyCardLabel">
                                Sender:&nbsp;
                              </span>
                              <span className="midText">{c.sender.name}</span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}

                          {c.senderAccount ? (
                            <>
                              <span className="tinyCardLabel">
                                Sender Account:&nbsp;
                              </span>
                              <span className="midText">
                                {accountHash[c.senderAccount]}
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}

                          {c.reciever ? (
                            <>
                              <span className="tinyCardLabel">
                                Receiver:&nbsp;
                              </span>
                              <span className="midText">{c.reciever.name}</span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          {c.recieverAccount ? (
                            <>
                              <span className="tinyCardLabel">
                                Receiver Account:&nbsp;
                              </span>
                              <span className="midText">
                                {accountHash[c.recieverAccount]}
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {currentUser.type === "admin" ? (
                        <>
                          <span className="tinyCardLabel">Branch:&nbsp;</span>
                          <span className="midText">{c.branch.label}</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </Row>

            <div className="mt-3">
              {"Items per Page: "}
              <select
                onChange={(e) => handlePageSizeChange(e.target.value)}
                value={itemsPerPage}
              >
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingtotal={1}
                boundarytotal={1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={handlePageChange}
                size="medium"
              />
            </div>
          </div>
        ) : loading ? (
          <Loader />
        ) : resetMsg != "" ? (
          <div className="defaultMsg">{resetMsg}</div>
        ) : (
          <div className="noResultsFound">{labels.NO_RESULTS}</div>
        )}
      </div>
    </>
  );
};

export default CashLogs;
