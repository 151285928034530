import React, { useState, useEffect } from "react";
import EventService from "../services/event.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from '../labels/en';
import { getBranches } from "../common/getFunctions";
import BranchePullDown from "../commonComponents/BranchePullDown";
import { Card, Row, Col } from "react-bootstrap";
import DateFromatter from "../common/DateFromatter";

const currentUser = JSON.parse(localStorage.getItem('user'));

const Events = () => {
    const [error, setError] = useState("");
    const [events, setEvents] = useState([]);
    const [branchFilter, setBranchFilter] = useState();
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resetMsg, setResetMsg] = useState("");
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(9);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(0);
    const pageSizes = [9, 18, 24, 30, 36, 60];

    useEffect(() => {
        async function onReady() {
            window.scrollTo(0, 0);
            setBranches(await getBranches());
            await listEvents("", page, itemsPerPage);
        }

        onReady()
    }, []);

    const listEvents = async (branchFilter, page, itemsPerPage) => {
        setLoading(true);
        EventService.getEventsList(branchFilter, parseInt(page - 1) * itemsPerPage, itemsPerPage, false).then(
            (response) => {
                let data = response.data.data;
                setEvents(data.list);
                setTotal(data ? data.total : 0);
                setCount(Math.ceil(data.total / itemsPerPage));
                setResetMsg("");
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setError(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");

                }
            }
        )


    }
    const handlePageChange = async (event, value) => {
        setPage(value);
        await listEvents(branchFilter, value, itemsPerPage);

    };

    const handlePageSizeChange = async (newItemsPerPage) => {

        setitemsPerPage(newItemsPerPage);
        setPage(1);
        await listEvents(branchFilter, 1, newItemsPerPage);

    };
    const resetSearch = async () => {
        setBranchFilter("");
        await listEvents("", page, itemsPerPage);
    }
    const triggerSearch = async () => {
        await setPage(1); // return to first page
        await listEvents(branchFilter, 1, itemsPerPage);

    }
    const handleBranchChange = async (bid) => {
        setBranchFilter(bid);
        resetResults();
    }
    const resetResults = () => {
        setTotal(0);
        setCount(0);
        setEvents([]);
        setResetMsg(labels.RESET_MSG);
    }



    const deleteEvent = async (id) => {

        if (window.confirm("Are you sure you want to delete this event?")) {
            EventService.deleteEvent(id).then(
                (response) => {

                    alert("Event deleted!");
                    let oldList = events;
                    var data = oldList.filter(function (obj) {
                        return obj._id !== id;
                    });
                    setEvents(data);
                    setTotal(total - 1)

                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setError(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");

                    }
                }
            );
        }
    }

    return (
        <div className="container">
            {currentUser.type === 'admin' ? <div className="floatRight "><NavLink to={"/event/new"} className="greenLink mx-0">Add Event</NavLink></div> : ""}
            <h3> Events List </h3>
            <div className="filterContainer">

                {currentUser && currentUser.type === 'admin' ?
                    <>
                        <BranchePullDown branch={branchFilter} labelInside={false} forceBlank={true} setBranch={handleBranchChange} branches={branches} />



                    </>
                    : null}


                <table border="0">
                    <tr>
                        <td><button onClick={(e) => triggerSearch(e)} className="btn btn-primary btn-block search-button ">Go</button></td>
                        <td><button onClick={(e) => resetSearch(e)} className="btn btn-primary btn-block reset-button ">Reset</button></td>
                    </tr>
                </table>
            </div>

            {error ? <header className="jumbotron"> <h3>{error}</h3>  </header> : null}
            {!error && !loading && events && events.length !== 0 ?

                <div>
                    <Row>
                        <Col><span style={{ float: "right", marginLeft: 10 }}>{total} results</span></Col>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "row", alignItems: "first baseline" }}>
                        {events.map((c) => (
                            <div style={{ float: "left", marginLeft: 20 }}>
                                <Card style={{ marginLeft: 0, maxWidth: 250, minHeight: 300, paddingLeft: 10, align: "top" }}>
                                    <Card.Body>
                                        <Card.Title>{c.title}</Card.Title>
                                        <NavLink to={"/event/" + c._id} className="text-dark midText"><i alt="View" title="View" className="fa fa-binoculars" ></i></NavLink>&nbsp;&nbsp;&nbsp;<NavLink to={"/event/edit/" + c._id} className="text-dark midText"><i alt="Edit" title="Edit" className="fa fa-pen" ></i></NavLink>&nbsp;&nbsp;&nbsp;<NavLink to={"/event/clone/" + c._id} className="text-dark "><i alt="Clone" title="Clone" className="fa fa-clone" ></i></NavLink>&nbsp;&nbsp;&nbsp;<a href="javascript:void(0)" className="text-dark midText" onClick={(e) => deleteEvent(c._id)} ><i alt="Delete" title="Delete" className="fa fa-trash" ></i></a><br />
                                        {c.company ? <><span className="tinyCardLabel">Company:&nbsp;</span><span className="midText">{c.company ? c.company.name : ""}</span><br /></> : ""}
                                        {c.package ? <><span className="tinyCardLabel">Package:&nbsp;</span><span className="midText">{c.package ? c.package.title : ""}</span><br /></> : ""}
                                        <span className="tinyCardLabel">Start Date:&nbsp;</span><span className="midText">{DateFromatter.beautifyDate(c.start)} {DateFromatter.tConvert(c.start)}</span><br />
                                        <span className="tinyCardLabel">End Date:&nbsp;</span><span className="midText">{DateFromatter.beautifyDate(c.end)} {DateFromatter.tConvert(c.end)}</span><br />
										{c.address ? <><span className="tinyCardLabel">Address:&nbsp;</span><span className="midText">{c.address}</span><br /></> : ""}
                                        {c.budget ? <><span className="tinyCardLabel">Budget:&nbsp;</span><span className="midText">{c.budget}</span><br /></> : ""}
                                        {c.fees ? <><span className="tinyCardLabel">Fees:&nbsp;</span><span className="midText">{c.fees}</span><br /></> : ""}
                                        {c.link ? <><span className="tinyCardLabel">Embeded Links:&nbsp;</span><span className="midText">{c.link}</span><br /></> : ""}
                                        <span className="tinyCardLabel">Notes:&nbsp;</span><span className="midText">{c.notes}</span><br />
                                        {c.summary ? <><span className="tinyCardLabel">Summary:&nbsp;</span><span className="midText">{c.summary}</span><br /></> : ""}
                                        <span className="tinyCardLabel">Disabled:&nbsp;</span><span className="midText">{c.disabled ? <span className="red">Yes</span> : <span className="green">No</span>}</span><br />
                                        <span className="tinyCardLabel">Internal:&nbsp;</span><span className="midText">{c.internal ? <span className="red">Yes</span> : <span className="green">No</span>}</span><br />
                                        <span className="tinyCardLabel">Type:&nbsp;</span><span className="midText">{c.type}</span><br />
                                        {currentUser.type === 'admin' ? <><span className="tinyCardLabel">Branch:&nbsp;</span><span className="midText">{c.branch.label}</span><br /></> : ""}
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </Row>


                    <div className="mt-3">
                        {"Items per Page: "}
                        <select onChange={(e) => handlePageSizeChange(e.target.value)} value={itemsPerPage}>
                            {pageSizes.map((size) => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>

                        <Pagination
                            className="my-3"
                            count={count}
                            page={page}
                            siblingtotal={1}
                            boundarytotal={1}
                            variant="outlined"
                            color="primary"
                            shape="rounded"
                            onChange={handlePageChange}
                            size="medium"

                        />
                    </div>
                </div>
                : loading ? <Loader /> :
                    resetMsg !== "" ? <div className='defaultMsg'>{resetMsg}</div> :
                        <div className="noResultsFound">{labels.NO_RESULTS}</div>

            }


        </div>
    );
};

export default Events;
