import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import BatService from "../services/bat.service";
import EventBus from "../common/EventBus";
import DateFromatter from "../common/DateFromatter";
import MaintenanceList from "../commonComponents/MaintenanceList";


const currentUser = JSON.parse(localStorage.getItem('user'));
const Bat = (props) => {
	const form = useRef();
	const checkBtn = useRef();

	const [number, setNumber] = useState("");
	const [voltage, setVoltage] = useState("");
	const [ampere, setAmpere] = useState("");
	const [status, setStatus] = useState("");
	const [model, setModel] = useState("");
	const [successful, setSuccessful] = useState(false);
	const [branch, setBranch] = useState("");
	const [message, setMessage] = useState("");
	const batId = props.match.params.id;

	const [note, setNote] = useState("");
	const [stolenDate, setStolenDate] = useState(new Date());

const loadBat= () => {
		BatService.getBatById(batId).then(
			(response) => {
				let b = response.data;
				setNumber(b.number);
				setVoltage(b.voltage);
				setModel(b.model ? b.model.label : "");
				setVoltage(b.model ? b.model.voltage + " V" : "");
				setAmpere(b.model ? b.model.ampere + " AH" : "");
				setStatus(b.status);
				setBranch(b.branch.label);
				console.log(b.branch)
				setNote(b.notes);
				setStolenDate(b.stolenDate);
			},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
				alert(_content);
				setMessage(_content);

				if (error.response && error.response.status === 401) {
					EventBus.dispatch("logout");
				}
			}
		);
	}


	return (
		<div className="col-md-12">

			<table className="styled-table" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ width: "50%" }}>Battery Specification</th>
						<th style={{ float: "right" }}>{currentUser.type == "admin" || currentUser.type == "manager" ?
							<><NavLink to={"/bat/edit/" + batId} className="text-dark ">Edit</NavLink></> : ""}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Status</td>
						<td>{status}</td>
					</tr>
					<tr>
						<td>Branch</td>
						<td>{branch}</td>
					</tr>
					<tr>
						<td>Model</td>
						<td>{model}</td>
					</tr>
					<tr>
						<td>Number</td>
						<td>{number}</td>
					</tr>
					<tr>
						<td>Voltage</td>
						<td>{voltage}</td>
					</tr>
					<tr>
						<td>Ampere</td>
						<td>{ampere}</td>
					</tr>
					<tr>
						<td>Notes</td>
						<td>{note ? note : ""}</td>
					</tr>
					{status === "Stolen" ?
						<tr>
							<td>Stolen Date</td>
							<td>{stolenDate ? DateFromatter.DateFormatterOne(stolenDate) : ""}</td>
						</tr>
						: ""}


				</tbody>
			</table>

			<table className="styled-table" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ width: "33%" }}>Maintenance</th>

					</tr>
				</thead>
				<tbody>

					<tr className="white-background">
						<td>
						<MaintenanceList 
						listView={true} 
						showFilters={false} 
						currentUser={currentUser}
						typeFilter="battery"
						batId={batId}
						isView={true}
						noResultsFound="noResultsFoundNoBorder"
						readyFunction={loadBat}
						statusFilter="all"
						
						/> 
						</td>

					</tr>


				</tbody>
			</table>




		</div>
	);
};

export default Bat;
