import React, { useState, useEffect } from "react";
import MaintenanceService from "../services/maintenance.service";
import EventBus from "../common/EventBus";
import AuthService from "../services/charger.service";
import { NavLink } from "react-router-dom";
import DateFromatter from "../common/DateFromatter";

export default function Maintenance(props) {

	const [maintenance, setMaintenance] = useState({ startdate: Date.now, enddate: "", bikeId: "", user: "", branch: { label: "" }, category: "", subcategory: "", location: { longitude: "", latitude: "", name: "" }, comments: "", diagnose: "", status: 0, obsInventories: [], repairedInventories: [], recycledInventories: [] , lostInventories: [] })

	const maintenanceId = props.match.params.id;
	const currentUser = JSON.parse(localStorage.getItem('user'));
	const getMaintenanceInfo = () => {
		if (maintenanceId) {
			MaintenanceService.getMaintenanceDetailsById(maintenanceId).then(
				(response) => {
					setMaintenance(response.data);
					
				},
				(error) => {
					const _content =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
					alert(_content);


					if (error.response && error.response.status === 401) {
						EventBus.dispatch("logout");
					}
				}
			);
		}


	}

	useEffect(() => {
		async function onReady() {
			window.scrollTo(0, 0);
			getMaintenanceInfo();

		}
		onReady();
	}, []);



	return (
		<div className="col-md-12">

			<table className="styled-table" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ width: "50%" }}>Maintenance Specification</th>
						<th style={{ float: "right" }}><NavLink to={"/maintenance/edit/" + maintenanceId} className="text-dark ">Edit</NavLink></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Start Date</td>
						<td>{DateFromatter.DateFormatterThree(maintenance.startdate)}</td>
					</tr>
					<tr>
						<td>End Date</td>
						{maintenance.enddate === "" ? <td>Not Complete</td> : <td>{DateFromatter.DateFormatterThree(maintenance.enddate)}</td>}
					</tr>
					<tr>
						<td>Type</td>
						<td className="upperCase">{maintenance.type ? maintenance.type : "Bike"}</td>
					</tr>
					<tr>
						<td>Number</td>
						<td>{maintenance.number}</td>
					</tr>
					<tr>
						<td>Biker</td>
						<td>{maintenance.usersubscribe ? maintenance.usersubscribe.full_name : ""}</td>
					</tr>
					<tr>
						<td>Category</td>
						<td>{maintenance.category}</td>
					</tr>
					<tr>
						<td>Sub Category</td>
						<td>{maintenance.type == "bike" ? maintenance.subcategory : "N/A"}</td>
					</tr>
					<tr>
						<td>Comments</td>
						<td>{maintenance.comments}</td>
					</tr>
					<tr>
						<td>Diagnose</td>
						<td>{maintenance.diagnose}</td>
					</tr>
					{maintenance.type == "bike" ?
						<tr>
							<td>Odometer</td>
							<td>{maintenance.bikeId ? maintenance.bikeId.kilometers : ""}</td>
						</tr> : ""
					}
					<tr>
						<td>Branch</td>
						<td>{maintenance.branch.label}</td>
					</tr>
					<tr>
						<td>Status</td>
						<td>{maintenance.status}</td>
					</tr>
				</tbody>
			</table>

			<table className="styled-table" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ width: "100%" }}>Maintenance Location</th>
						
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
						{maintenance.location  && maintenance.location.latitude  && maintenance.location.longitude ? 
						<iframe scrolling="no" width="100%" marginheight="0" marginwidth="0" src={"https://maps.google.com/maps?q="+ maintenance.location.latitude + "," + maintenance.location.longitude +"&t=m&z=12&output=embed&iwloc=near"} title={maintenance.location.name}  alt={maintenance.location.name}  aria-label={maintenance.location.name} frameborder="0"></iframe>: "Location is not set."}
						</td>
					</tr>
					
				</tbody>
			</table>
			{maintenance.type == "bike" ?
				<>
					<table className="styled-table" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Repaired Inventories</th>
								<th style={{ width: "25%" }}></th>
								<th style={{ width: "25%" }}></th>

							</tr>
						</thead>
						<tbody>
							{
								maintenance.repairedInventories.map((item, index) =>
									<tr key={index}>
										<td>{item.number}</td>
										<td>{item.label}</td>
										<td>{item.type}</td>
									</tr>
								)
							}

						</tbody>
					</table>
					<table className="styled-table" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Recycled Inventories</th>
								<th style={{ width: "25%" }}></th>
								<th style={{ width: "25%" }}></th>

							</tr>
						</thead>
						<tbody>
							{
								maintenance.recycledInventories.map((item, index) =>
									<tr key={index}>
										<td>{item.number}</td>
										<td>{item.label}</td>
										<td>{item.type}</td>
									</tr>
								)
							}

						</tbody>
					</table>
					<table className="styled-table" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Lost Inventories</th>
								<th style={{ width: "25%" }}></th>
								<th style={{ width: "25%" }}></th>

							</tr>
						</thead>
						<tbody>
							{
								maintenance.lostInventories.map((item, index) =>
									<tr key={index}>
										<td>{item.number}</td>
										<td>{item.label}</td>
										<td>{item.type}</td>
									</tr>
								)
							}

						</tbody>
					</table>

					<table className="styled-table" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Obs Inventories</th>
								<th style={{ width: "25%" }}></th>
								<th style={{ width: "25%" }}></th>

							</tr>
						</thead>
						<tbody>
							{
								maintenance.obsInventories.map((item, index) =>
									<tr key={index}>
										<td>{item.number}</td>
										<td>{item.label}</td>
										<td>{item.type}</td>
									</tr>
								)
							}

						</tbody>
					</table>
				</>
				: ""}





		</div>
	);
};



