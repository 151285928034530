import React, { useState, useEffect, useRef } from "react";
import Loader from "../commonComponents/Loader";
import DatePicker from "react-datepicker";
import * as labels from "../labels/en";
import CashLogService from "../services/cashLog.service";
import {
  getBranches,
  getBranchAccounts,
  getCashlogCategories,
} from "../common/getFunctions";
import EventBus from "../common/EventBus";
import BranchePullDown from "../commonComponents/BranchePullDown";
import commonFunctions from "../common/Functions";
import { Row, Col } from "react-bootstrap";
import PullDown from "../commonComponents/PullDown";
import { CSVLink } from "react-csv";
import NewSelect from "react-select";

const currentUser = JSON.parse(localStorage.getItem("user"));
const CashLogSummary = (props) => {
  const form = useRef();
  const [error, setError] = useState("");
  const [branchFilter, setBranchFilter] = useState();
  const [resetMsg, setResetMsg] = useState("");
  const [branches, setBranches] = useState([]);
  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accountsFilter, setAccountsFilter] = useState();
  const [branchAccounts, setBranchAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [isCash, setIsCash] = useState(false);
  const [logsPwd, setLogsPwd] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleBranchChange = async (bid, triggerSearch = false) => {
    setBranchFilter(bid);
    setCategories(await getCashlogCategories(bid));
    loadAccountBranch(bid, triggerSearch);
    setAccountsFilter("");
  };

  const loadAccountBranch = async (bid, triggerSearch = false) => {
    let optionsList = [{ value: "all", label: "Select All" }];
    let accFilter = [];
    let isCash = false;
    if (bid) {
      const accountsT = await getBranchAccounts(bid);

      setBranchAccounts(accountsT);

      if (accountsT) {
        accountsT.map((l) => {
          const accObj = { value: l.key, label: l.label, type: l.type };
          optionsList.push(accObj);
          if (l.type === "cash") {
            accFilter.push(accObj);
            isCash = true;
          }
        });
        setAccountList(optionsList);
        setAccountsFilter(accFilter);
      }
    } else {
      setAccountList(optionsList);
    }
    setIsCash(isCash);
    if (triggerSearch) {
      getSummary(bid, accFilter, "", logsPwd, isCash, fromDate, toDate);
    }
  };

  const handlePwdChanged = (val) => {
    setLogsPwd(val);
    resetResults();
  };

  const handleAccountChange = async (account) => {
    if (account.some((option) => option.value === "all")) {
      const selectedValues = accountList
        .filter((account) => account.value !== "all")
        .map((account) => account);

      // If "Select All" is selected, set all options as selected
      setAccountsFilter(selectedValues);
    } else {
      // Otherwise, set the selected accounts as usual
      setAccountsFilter(account);
    }
    let isCashOny = true;
    // check if any account is not cash.
    account.map((acc) => {
      if (acc.type !== "cash") {
        isCashOny = false;
      }
    });
    setIsCash(isCashOny);
    resetResults();
  };

  const handleCategoryChange = async (category) => {
    setCategoryFilter(category);
    resetResults();
  };

  const resetResults = () => {
    setSummary([]);
    setResetMsg(labels.RESET_MSG);
  };
  const getSummary = async (
    bid,
    accounts,
    category,
    logsPwd,
    isCash,
    fromDate,
    toDate
  ) => {
    if (logsPwd === "" && !isCash && currentUser.type !== "accountant") {
      setResetMsg(labels.CONFIDENTIAL_INFO);
      setLoading(false);
      return;
    }

    setLoading(true);

    await CashLogService.getSummary(
      bid,
      accounts,
      category,
      logsPwd,
      isCash,
      fromDate,
      toDate
    ).then(
      (response) => {
        console.log(response?.data?.data?.results);
        const sResults = response?.data?.data?.results;
        const newSummary = [];
        if (sResults) {
          sResults.map((s, i) => {
            s.total =
              s.result && s.result[0]
                ? commonFunctions.CommaFormatted(s.result[0].total) +
                  " " +
                  s.result[0]._id
                : 0;
            newSummary.push(s);
          });
        }
        setSummary(newSummary);

        setLoading(false);
        setResetMsg("");
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setError(_content);
        setResetMsg(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };
  const triggerSearch = async () => {
    await getSummary(
      branchFilter,
      accountsFilter,
      categoryFilter,
      logsPwd,
      isCash,
      fromDate,
      toDate
    );
  };
  const resetSearch = async () => {
    setCategoryFilter("");
    setAccountsFilter("");
    setBranchFilter(currentUser.branch);
    setFromDate("");
    setToDate("");
    setIsCash(false);
    setSummary([]);
    getSummary(currentUser.branch, [], "", logsPwd, false, "");
    //setResetMsg(labels.RESET_MSG);
  };

  const handleTransactionFromDate = (val) => {
    setFromDate(val);
    resetResults();
  };

  const handleTransactionToDate = (val) => {
    setToDate(val);
    resetResults();
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      setFromDate("");
      setToDate("");
      handleBranchChange(currentUser.branch, true);
    }

    onReady();
  }, []);

  const logsHeader = [
    { label: "Account", key: "title" },
    { label: "Type", key: "type" },
    { label: "Total", key: "total" },
  ];
  const monthsNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const currentDate = new Date();
  return (
    <div className="container">
      <h3> Transactions Summary </h3>
      <div className="col-md-12">
        <Row style={{ display: "flex", flexDirection: "row" }}>
          <Col md={10}>
            <label
              className="filterLabelInput maintenanceLabels"
              htmlFor="accountsFilter"
            >
              Accounts
            </label>
            <NewSelect
              autocomplete="off"
              value={accountsFilter}
              isMulti={true}
              onChange={(selectedAccount) => {
                handleAccountChange(selectedAccount);
              }}
              options={accountList}
              isClearable
            />
          </Col>
        </Row>
        <Row style={{ display: "flex", flexDirection: "row" }}>
          <Col md={2}>
            <BranchePullDown
              branch={branchFilter}
              addFormGroup="no"
              extraClassLabel="filterLabelInput maintenanceLabels"
              labelInside={false}
              forceBlank={true}
              setBranch={handleBranchChange}
              branches={branches}
            />
          </Col>

          <Col md={2}>
            <label
              className="filterLabelInput maintenanceLabels"
              htmlFor="categoryFilter"
            >
              Category
            </label>
            <PullDown
              defaultValue={categoryFilter}
              labelInside={false}
              pulldownFor={"categoryFilter"}
              forceBlank={true}
              setValue={handleCategoryChange}
              options={categories}
            />
          </Col>
          <Col md={2}>
            <label
              className="filterLabelInput maintenanceLabels"
              htmlFor="fromDate"
            >
              From
            </label>
            <DatePicker
              autoComplete={false}
              dateFormat="dd-MM-yyyy"
              className="form-control filterInput2"
              name="fromDate"
              selected={fromDate}
              onChange={(date) => handleTransactionFromDate(date)}
            />
          </Col>
          <Col md={2}>
            <label
              className="filterLabelInput maintenanceLabels"
              htmlFor="toDate"
            >
              To
            </label>
            <DatePicker
              autoComplete={false}
              dateFormat="dd-MM-yyyy"
              className="form-control filterInput2"
              name="toDate"
              selected={toDate}
              onChange={(date) => handleTransactionToDate(date)}
            />
          </Col>
          {currentUser.type !== "accountant" && (
            <Col md={2}>
              <label
                className="filterLabelInput maintenanceLabels"
                htmlFor="logsPwd"
              >
                Password
              </label>
              <input
                type="password"
                className="form-control filterInput2"
                name="logsPwd"
                value={logsPwd}
                onChange={(e) => handlePwdChanged(e.target.value)}
              />
            </Col>
          )}
          <Col md={2}>
            <table style={{ marginTop: 40 }} border="0">
              <tr>
                <td>
                  <button
                    onClick={(e) => triggerSearch(e)}
                    className="btn btn-primary btn-block search-button "
                  >
                    Go
                  </button>
                </td>
                <td>
                  <button
                    onClick={(e) => resetSearch(e)}
                    className="btn btn-primary btn-block reset-button "
                  >
                    Reset
                  </button>
                </td>
              </tr>
            </table>
          </Col>
        </Row>
      </div>
      {!error && summary.length !== 0 ? (
        <div className="col-md-12">
          <div
            className="floatRight "
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <CSVLink
              data={summary}
              headers={logsHeader}
              filename={
                currentDate.getDate() +
                "-" +
                monthsNames[currentDate.getMonth()] +
                "-" +
                currentDate.getFullYear() +
                "-" +
                "transactions.csv"
              }
            >
              <i alt="View" title="View" className="fa fa-download"></i> Export
            </CSVLink>
          </div>

          <table className="styled-table" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Account</th>
                <th>Type</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {summary?.map((s) => (
                <tr>
                  <td>{s.title}</td>
                  <td>{s.type}</td>

                  <td>{s.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : loading ? (
        <Loader />
      ) : resetMsg !== "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
    </div>
  );
};

export default CashLogSummary;
