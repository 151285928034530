import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/logo2.png";
import { menu_select_hover_color } from "../labels/options_en";
import { Navbar, Nav } from "react-bootstrap";

export default function TechnicalMenu(props) {
  const user = props.user;
  const type = props.type;
  const logOut = props.logOut;
  const [expanded, setExpanded] = useState(false);

  return (
    <div style={{ marginBottom: 30 }}>
      <div className="technicalMenu">
        <Navbar
          expand="lg"
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
          className="technicalMenuNavBar"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse className="pl-3">
            <Nav className="technicalBigNav">
              <Nav className="list-unstyled components mr-auto technicalNav">
                {type === "reseller" ? (
                  <Nav.Link onClick={() => setExpanded(false)}>
                    <NavLink
                      to={"/dashboard"}
                      className="nav-link technical-menu-link"
                    >
                      <img
                        className="logoImage"
                        style={{ width: 150 }}
                        src={logo}
                        alt="logo"
                      />
                    </NavLink>
                  </Nav.Link>
                ) : (
                  ""
                )}
                {type === "reseller" ? (
                  <Nav.Link onClick={() => setExpanded(false)}>
                    <NavLink
                      style={(isActive) => ({
                        color: "white",
                        fontWeight: isActive ? "bold" : "normal",
                      })}
                      className="nav-link technical-menu-link-2"
                      to={"/company/" + props.company}
                    >
                      Company
                    </NavLink>
                  </Nav.Link>
                ) : (
                  ""
                )}
                {type !== "reseller" ? (
                  <>
                    <Nav.Link onClick={() => setExpanded(false)}>
                      <NavLink
                        style={(isActive) => ({
                          color: "white",
                          fontWeight: isActive ? "bold" : "normal",
                        })}
                        className="nav-link technical-menu-link-2"
                        to={"/maintenances"}
                      >
                        Maintenance List
                      </NavLink>
                    </Nav.Link>

                    <Nav.Link onClick={() => setExpanded(false)}>
                      <NavLink
                        style={(isActive) => ({
                          color: "white",
                          fontWeight: isActive ? "bold" : "normal",
                        })}
                        className="nav-link technical-menu-link-2"
                        to={"/maintenance/new"}
                      >
                        Add Maintenance
                      </NavLink>
                    </Nav.Link>
                  </>
                ) : (
                   <Nav.Link onClick={() => setExpanded(false)}>
                      <NavLink
                        style={(isActive) => ({
                          color: "white",
                          fontWeight: isActive ? "bold" : "normal",
                        })}
                        className="nav-link technical-menu-link-2"
                        to={"/reseller/invoices"}
                      >
                        Invoices
                      </NavLink>
                    </Nav.Link>
                )}

                <Nav.Link onClick={() => setExpanded(false)}>
                  <NavLink
                    className="nav-link technical-menu-link-2"
                    style={(isActive) => ({
                      color: "white",
                      fontWeight: isActive ? "bold" : "normal",
                    })}
                    to={"/reseller/requests"}
                  >
                    Maintenance History
                  </NavLink>
                </Nav.Link>
                
              </Nav>
              <Nav className="technicalLogout">
			  <Nav.Link onClick={() => setExpanded(false)}>
                  <NavLink
                    className="nav-link technical-menu-link-2"
                    style={(isActive) => ({
                      color: "white",
                      fontWeight: isActive ? "bold" : "normal",
                    })}
                    to={"/profile"}
                  >
                    Profile
                  </NavLink>
                </Nav.Link>
                <Nav.Link onClick={() => setExpanded(false)}>
                  <NavLink
                    className="nav-link technical-menu-link-2"
                    style={(isActive) => ({
                      color: "white",
                      fontWeight: isActive ? "bold" : "normal",
                    })}
                    to={"/changepassword"}
                  >
                    Change Password
                  </NavLink>
                </Nav.Link>
                <Nav.Link>
                  <a
                    href="/"
                    style={{ color: "white" }}
                    className="nav-link technical-menu-link-2"
                    onClick={logOut}
                  >
                    LogOut&nbsp;<i className="fas fa-sign-out-alt"></i>
                  </a>
                </Nav.Link>
              </Nav>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}
