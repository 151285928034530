import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/logo2.png";
import Accordion from "react-bootstrap/Accordion";
import { menu_select_hover_color } from "../labels/options_en";
import { Navbar, Nav } from "react-bootstrap";

export default function UserMenu(props) {
  const user = props.user;
  const type = props.type;
  const logOut = props.logOut;

  const showTechExpand = type === "admin" ? "1" : "0";
  const showHelpDeskExpand = type === "admin" ? "1" : "0";

  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      id="sidebar"
      expand="xl"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      className="userMenu"
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="pt-5">
        <Nav className="list-unstyled components mb-5 p-4 pt-5 flex-column">
          <Navbar.Brand onClick={() => setExpanded(false)}>
            <Link to={"/dashboard"} className="navbar-brand pb-4">
              <img className="logoImage" src={logo} alt="logo" />
            </Link>
          </Navbar.Brand>
          {type === "admin" && (
            <Nav.Link onClick={() => setExpanded(false)}>
              <NavLink
                style={(isActive) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                to={"/users"}
                className="nav-link greenLink mx-0"
              >
                Users
              </NavLink>
            </Nav.Link>
          )}

          <Nav.Link>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Settings{" "}
                  <i
                    style={{ float: "right" }}
                    className="fas fa-caret-down"
                  ></i>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="list-unstyled" id="homeSubmenu">
                    <li>
                      <NavLink
                        onClick={() => setExpanded(false)}
                        style={(isActive) => ({
                          fontWeight: isActive ? "bold" : "normal",
                        })}
                        to={"/profile"}
                        className="nav-link greenLink"
                      >
                        <i className="fas fa-user"></i>&nbsp;&nbsp;Profile
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => setExpanded(false)}
                        style={(isActive) => ({
                          fontWeight: isActive ? "bold" : "normal",
                        })}
                        to={"/changepassword"}
                        className="nav-link greenLink"
                      >
                        <i className="fas fa-unlock-alt"></i>&nbsp;&nbsp;Change
                        Password
                      </NavLink>
                    </li>
                    {type === "admin" ? (
                      <>
                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              fontWeight: isActive ? "bold" : "normal",
                            })}
                            to={"/categories"}
                            className="nav-link greenLink"
                          >
                            <i className="fas fa-layer-group"></i>
                            &nbsp;&nbsp;Categories
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              fontWeight: isActive ? "bold" : "normal",
                            })}
                            to={"/plans"}
                            className="nav-link greenLink"
                          >
                            <i className="fas fa-lightbulb"></i>
                            &nbsp;&nbsp;Plans
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              fontWeight: isActive ? "bold" : "normal",
                            })}
                            to={"/branches"}
                            className="nav-link greenLink"
                          >
                            <i className="fas fa-code-branch"></i>
                            &nbsp;&nbsp;Branches
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Nav.Link>

          <Nav.Link>
            <Accordion defaultActiveKey={showTechExpand}>
              <Accordion.Item eventKey="0">
                {type === "admin" ? (
                  <Accordion.Header>
                    Technical{" "}
                    <i
                      style={{ float: "right" }}
                      className="fas fa-caret-down"
                    ></i>
                  </Accordion.Header>
                ) : null}
                <Accordion.Body>
                  <ul className="list-unstyled" id="homeSubmenu">
                    {(type === "admin" ||
                      type === "technical" ||
                      type === "manager") && (
                      <>
                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              fontWeight: isActive ? "bold" : "normal",
                            })}
                            to={"/models"}
                            className="nav-link greenLink mx-0"
                          >
                            <i className="fas fa-vector-square"></i>
                            &nbsp;&nbsp;Models
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              fontWeight: isActive ? "bold" : "normal",
                            })}
                            to={"/chargers"}
                            className="nav-link greenLink mx-0"
                          >
                            <i className="fas fa-charging-station"></i>
                            &nbsp;&nbsp;Chargers
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              fontWeight: isActive ? "bold" : "normal",
                            })}
                            to={"/bats"}
                            className="nav-link greenLink mx-0"
                          >
                            <i className="fas fa-battery-three-quarters"></i>
                            &nbsp;&nbsp;Batteries
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              fontWeight: isActive ? "bold" : "normal",
                            })}
                            to={"/inventories"}
                            className="nav-link greenLink mx-0"
                          >
                            <i className="fas fa-boxes"></i>
                            &nbsp;&nbsp;Inventories
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              fontWeight: isActive ? "bold" : "normal",
                            })}
                            to={"/bikes"}
                            className="nav-link greenLink mx-0"
                          >
                            <i className="fas fa-bicycle"></i>&nbsp;&nbsp;Bikes
                          </NavLink>
                        </li>
                      </>
                    )}
                    {(type === "admin" ||
                      type === "technical" ||
                      type === "manager" ||
                      type === "helpdesk") && (
                      <>
                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              fontWeight: isActive ? "bold" : "normal",
                            })}
                            to={"/maintenances"}
                            className="nav-link greenLink mx-0"
                          >
                            <i className="fas fa-tools"></i>
                            &nbsp;&nbsp;Maintenance
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              fontWeight: isActive ? "bold" : "normal",
                            })}
                            to={"/reseller/requests"}
                            className="nav-link greenLink mx-0"
                          >
                            <i className="fas fa-truck-fast"></i>
                            &nbsp;&nbsp;Requests
                          </NavLink>
                        </li>
                      </>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Nav.Link>

          {(type === "admin" || type === "helpdesk") && (
            <Nav.Link>
              <Accordion defaultActiveKey={showHelpDeskExpand}>
                <Accordion.Item eventKey="0">
                  {type === "admin" ? (
                    <Accordion.Header>
                      Help Desk{" "}
                      <i
                        style={{ float: "right" }}
                        className="fas fa-caret-down"
                      ></i>
                    </Accordion.Header>
                  ) : null}

                  <Accordion.Body>
                    <ul className="list-unstyled" id="homeSubmenu">
                      {/*<li>
                                                <NavLink
                                                    onClick={() => setExpanded(false)}
                                                    style={isActive => ({
                                                         fontWeight: isActive ? "bold" : "normal"
                                                    })}
                                                    to={"/surveys"}
                                                    className="nav-link greenLink mx-0"
                                                >
                                                    <i className="fas fa-tasks"></i>&nbsp;&nbsp;Surveys
                                                </NavLink>
                                            </li>*/}
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/onBoarding"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-tasks"></i>&nbsp;&nbsp;On
                          Boarding
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/clients"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-users"></i>&nbsp;&nbsp;Wavers
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/companies"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-handshake"></i>
                          &nbsp;&nbsp;Companies
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/packages"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-box-open"></i>
                          &nbsp;&nbsp;Packages
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/events"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-calendar-days"></i>
                          &nbsp;&nbsp;Events
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/followup"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="far fa-calendar-alt"></i>
                          &nbsp;&nbsp;Follow Up
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/invoices"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-receipt"></i>&nbsp;&nbsp;Invoices
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/waitings"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-pause-circle"></i>
                          &nbsp;&nbsp;Waiting List
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/subscriptions"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-rocket"></i>
                          &nbsp;&nbsp;Subscriptions
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/faqs"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-question"></i>&nbsp;&nbsp;FAQ's
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            fontWeight: isActive ? "bold" : "normal",
                          })}
                          to={"/helpdesk"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-comments"></i>
                          &nbsp;&nbsp;Enquiries
                        </NavLink>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Nav.Link>
          )}

          {(type === "admin" || type === "manager" || type === "helpdesk") && (
            <Nav.Link>
              <Accordion defaultActiveKey={showHelpDeskExpand}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Surveys
                    <i
                      style={{ float: "right" }}
                      className="fas fa-caret-down"
                    ></i>
                  </Accordion.Header>

                  <Accordion.Body>
                    <ul className="list-unstyled" id="homeSubmenu">
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            color: isActive ? menu_select_hover_color : "white",
                          })}
                          to={"/forms/all"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-users"></i>&nbsp;&nbsp;All
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            color: isActive ? menu_select_hover_color : "white",
                          })}
                          to={"/forms/journeys"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-map-marked-alt"></i>
                          &nbsp;&nbsp;Journeys
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            color: isActive ? menu_select_hover_color : "white",
                          })}
                          to={"/forms/events"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-calendar-days"></i>
                          &nbsp;&nbsp;Events
                        </NavLink>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Nav.Link>
          )}

          {(type === "admin" || type === "manager" || type === "helpdesk") && (
            <Nav.Link>
              <Accordion defaultActiveKey={showHelpDeskExpand}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Analytics
                    <i
                      style={{ float: "right" }}
                      className="fas fa-caret-down"
                    ></i>
                  </Accordion.Header>

                  <Accordion.Body>
                    <ul className="list-unstyled" id="homeSubmenu">
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            color: isActive ? menu_select_hover_color : "white",
                          })}
                          to={"/analytics/subscriptions"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-rocket"></i>
                          &nbsp;&nbsp;Subscriptions
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            color: isActive ? menu_select_hover_color : "white",
                          })}
                          to={"/analytics/registered"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-users"></i>&nbsp;&nbsp;Registered
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            color: isActive ? menu_select_hover_color : "white",
                          })}
                          to={"/analytics/terminations"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-power-off"></i>
                          &nbsp;&nbsp;Terminations
                        </NavLink>
                      </li>
                      {type === "admin" ? (
                        <li>
                          <NavLink
                            onClick={() => setExpanded(false)}
                            style={(isActive) => ({
                              color: isActive
                                ? menu_select_hover_color
                                : "white",
                            })}
                            to={"/analytics/revenue"}
                            className="nav-link greenLink mx-0"
                          >
                            <i className="fas fa-dollar-sign"></i>
                            &nbsp;&nbsp;Revenue
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Nav.Link>
          )}

          {(type === "admin" || type === "manager" || type === "helpdesk") && (
            <Nav.Link>
              <Accordion defaultActiveKey={showHelpDeskExpand}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Transactions
                    <i
                      style={{ float: "right" }}
                      className="fas fa-caret-down"
                    ></i>
                  </Accordion.Header>

                  <Accordion.Body>
                    <ul className="list-unstyled" id="homeSubmenu">
                      <li>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            color: isActive ? menu_select_hover_color : "white",
                          })}
                          to={"/cashlogs"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-list"></i>&nbsp;&nbsp;List
                        </NavLink>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            color: isActive ? menu_select_hover_color : "white",
                          })}
                          to={"/cashlog/new"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-cash-register"></i>
                          &nbsp;&nbsp;Add
                        </NavLink>
                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={(isActive) => ({
                            color: isActive ? menu_select_hover_color : "white",
                          })}
                          to={"/cashlog/summary"}
                          className="nav-link greenLink mx-0"
                        >
                          <i className="fas fa-money-bill"></i>
                          &nbsp;&nbsp;Summary
                        </NavLink>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Nav.Link>
          )}

          {type === "admin" ? (
            <Nav.Link>
              <NavLink
                onClick={() => setExpanded(false)}
                style={(isActive) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                to={"/locations"}
                className="nav-link greenLink mx-0"
              >
                <i className="fas fa-map-marked-alt"></i>&nbsp;&nbsp;Locations
              </NavLink>
            </Nav.Link>
          ) : null}

          <Nav.Link>
            <a href="/" className="nav-link greenLink mx-0" onClick={logOut}>
              <i className="fas fa-sign-out-alt"></i>&nbsp;&nbsp;LogOut
            </a>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
