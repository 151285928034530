import React, { useState, useEffect } from "react";
import authHeader from "../services/auth-header";
import companieservice from "../services/company.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import commonFunctions from "../common/Functions";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import * as options from "../labels/options_en";
import { getBranches } from "../common/getFunctions";
import BranchePullDown from "../commonComponents/BranchePullDown";
import { Card, Row, Col } from "react-bootstrap";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";
import InternalNoteModal from "../commonComponents/InternalNoteModal";

const currentUser = JSON.parse(localStorage.getItem("user"));

const Companies = () => {
  const [error, setError] = useState("");
  const [companies, setCompanies] = useState([]);
  const [branchFilter, setBranchFilter] = useState();
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const pageSizes = [9, 18, 24, 30, 36, 60];
  const [internalNoteModal, setInternalNoteModal] = useState(false);
  const [oldInternalNote, setOldInternalNote] = useState("");
  const [selectedCompanyByNote, setSelectedCompanyByNote] = useState("");

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      await listCompanies("", page, itemsPerPage);
    }

    onReady();
  }, []);

  const listCompanies = async (branchFilter, page, itemsPerPage) => {
    setLoading(true);
    companieservice
      .getCompaniesList(
        branchFilter,
        parseInt(page - 1) * itemsPerPage,
        itemsPerPage,
        false
      )
      .then(
        (response) => {
          let data = response.data.data;
          setCompanies(data.list);
          setTotal(data ? data.total : 0);
          setCount(Math.ceil(data.total / itemsPerPage));
          setResetMsg("");
          setLoading(false);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
    await listCompanies(branchFilter, value, itemsPerPage);
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listCompanies(branchFilter, 1, newItemsPerPage);
  };
  const resetSearch = async () => {
    setBranchFilter("");
    await listCompanies("", page, itemsPerPage);
  };
  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listCompanies(branchFilter, 1, itemsPerPage);
  };
  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    resetResults();
  };
  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setCompanies([]);
    setResetMsg(labels.RESET_MSG);
  };

  const deleteCompany = async (event, id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this company? \n Make sure no packages are created for this company!"
      )
    ) {
      companieservice.deleteCompany(id).then(
        (response) => {
          alert("Company deleted!");
          let oldList = companies;
          var data = oldList.filter(function (obj) {
            return obj._id !== id;
          });
          setCompanies(data);
          setTotal(total - 1);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const handleAddInternalNoteClick = (id, internalNote) => {
    setSelectedCompanyByNote(id);
    setOldInternalNote(internalNote);
    setInternalNoteModal(true);
  };

  return (
    <>
      <InternalNoteModal
        internalNoteModal={internalNoteModal}
        setInternalNoteModal={setInternalNoteModal}
        selectedUserByNote={selectedCompanyByNote}
        oldInternalNote={oldInternalNote}
        list={companies}
        setList={setCompanies}
        isCompany={true}
      />
      <div className="container">
        {currentUser.type == "admin" ? (
          <div className="floatRight ">
            <NavLink to={"/company/new"} className="greenLink mx-0">
              Add Company
            </NavLink>
          </div>
        ) : (
          ""
        )}
        <h3> Companies List </h3>
        <div className="filterContainer">
          {currentUser && currentUser.type == "admin" ? (
            <>
              <BranchePullDown
                branch={branchFilter}
                labelInside={false}
                forceBlank={true}
                setBranch={handleBranchChange}
                branches={branches}
              />
            </>
          ) : null}

          <table border="0">
            <tr>
              <td>
                <br />
                <button
                  onClick={(e) => triggerSearch(e)}
                  className="btn btn-primary btn-block search-button "
                >
                  Go
                </button>
              </td>
              <td>
                <br />
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary btn-block reset-button "
                >
                  Reset
                </button>
              </td>
            </tr>
          </table>
        </div>

        {error ? (
          <header className="jumbotron">
            {" "}
            <h3>{error}</h3>{" "}
          </header>
        ) : null}
        {!error && !loading && companies && companies.length != 0 ? (
          <div>
            <Row>
              <Col>
                <span style={{ float: "right", marginLeft: 10 }}>
                  {total} results
                </span>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "first baseline",
              }}
            >
              {companies.map((c) => (
                <div style={{ float: "left", marginLeft: 20 }}>
                  <Card
                    style={{
                      marginLeft: 0,
                      maxWidth: 250,
                      minHeight: 300,
                      paddingLeft: 10,
                      align: "top",
                    }}
                  >
                    <Card.Body>
                      <Card.Title>{c.name}</Card.Title>
                      <NavLink
                        to={"/company/" + c._id}
                        className="text-dark midText"
                      >
                        <i
                          alt="View"
                          title="View"
                          className="fa fa-binoculars"
                        ></i>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        to={"/company/edit/" + c._id}
                        className="text-dark midText"
                      >
                        <i alt="Edit" title="Edit" className="fa fa-pen"></i>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        to={"/company/clone/" + c._id}
                        className="text-dark midText"
                      >
                        <i
                          alt="Clone"
                          title="Clone"
                          className="fa fa-clone"
                        ></i>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        href="javascript:void(0)"
                        className="text-dark midText"
                        onClick={(e) => deleteCompany(e, c._id)}
                      >
                        <i
                          alt="Delete"
                          title="Delete"
                          className="fa fa-trash"
                        ></i>
                      </a>
                      <br />
                      <NavLink
                        to={"/reseller/invoices/" + c._id}
                        className="text-dark midText"
                      >
                        Reseller Invoices
                      </NavLink>
                      <br />
                      <button
                        className="linkBtn greenLink mx-0"
                        onClick={() =>
                          handleAddInternalNoteClick(c._id, c.internalNote)
                        }
                      >
                        <i className="fa fa-comment">
                          {" "}
                          <span className="faText">Add an internal note</span>
                        </i>
                      </button>
                      <br />
                      <span className="tinyCardLabel">Phone:&nbsp;</span>
                      <span className="midText">{c.phone}</span>
                      <br />
                      <span className="tinyCardLabel">Email:&nbsp;</span>
                      <span className="midText">{c.email}</span>
                      <br />
                      <span className="tinyCardLabel">Industry:&nbsp;</span>
                      <span className="midText">
                        {c.industry ? c.industry : "None"}
                      </span>
                      <br />
                      <span className="tinyCardLabel">Address:&nbsp;</span>
                      <span className="midText">
                        {c.address ? c.address : "None"}
                      </span>
                      <br />
                      <span className="tinyCardLabel">Supplier:&nbsp;</span>
                      <span className="midText">
                        {c.isSupplier ? (
                          <span className="green">Yes</span>
                        ) : (
                          <span className="red">No</span>
                        )}
                      </span>
                      <br />
                      {currentUser.type === "admin" ? (
                        <>
                          <span className="tinyCardLabel">Branch:&nbsp;</span>
                          <span className="midText">{c.branch.label}</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </Row>

            <div className="mt-3">
              {"Items per Page: "}
              <select
                onChange={(e) => handlePageSizeChange(e.target.value)}
                value={itemsPerPage}
              >
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingtotal={1}
                boundarytotal={1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={handlePageChange}
                size="medium"
              />
            </div>
          </div>
        ) : loading ? (
          <Loader />
        ) : resetMsg != "" ? (
          <div className="defaultMsg">{resetMsg}</div>
        ) : (
          <div className="noResultsFound">{labels.NO_RESULTS}</div>
        )}
      </div>
    </>
  );
};

export default Companies;
