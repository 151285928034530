import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getCashLogsList = (
  branch,
  keyword,
  invoiceId,
  offset,
  limit,
  onlyList,
  accountFilter,
  categoryFilter,
  pwd,
  accountType,
  date
) => {
  return axios.get(
    API_URL +
      "/cashLog/list?branch=" +
      branch +
      "&keyword=" +
      keyword +
      "&invoiceId=" +
      invoiceId +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&onlyList=" +
      onlyList +
      "&accountFilter=" +
      accountFilter +
      "&category=" +
      categoryFilter +
      "&pwd=" +
      pwd +
      "&accountType=" +
      accountType +
      "&date=" +
      date,
    { headers: authHeader() }
  );
};

const getCategoriesList = (branch, usage, keyword, offset, limit) => {
  return axios.get(
    API_URL +
      "/cashLog/categories/list?branch=" +
      branch +
      "&usage=" +
      usage +
      "&keyword=" +
      keyword +
      "&offset=" +
      offset +
      "&limit=" +
      limit,
    { headers: authHeader() }
  );
};

const deleteCashLog = (id) => {
  return axios(API_URL + "/cashLog/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};

const postCashLog = (data) => {
  return axios(API_URL + "/cashLog/save", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const deleteCategory = (id) => {
  return axios(API_URL + "/cashLog/category/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};

const postCategory = (data) => {
  console.log(data);
  return axios(API_URL + "/cashLog/category/save", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const getSummary = (
  branch,
  accounts,
  categoryFilter,
  pwd,
  isCash,
  fromDate,
  toDate
) => {
  return axios.get(
    API_URL +
      "/cashLog/summary?branch=" +
      branch +
      "&category=" +
      categoryFilter +
      "&accounts=" +
      encodeURIComponent(JSON.stringify(accounts)) +
      "&pwd=" +
      pwd +
      "&isCash=" +
      isCash +
      "&fromDate=" +
      fromDate +
      "&toDate=" +
      toDate,
    { headers: authHeader() }
  );
};

const getCashLogById = (id) => {
  return axios.get(API_URL + "/cashLog/" + id, { headers: authHeader() });
};

const getCategoryById = (id) => {
  return axios.get(API_URL + "/cashLog/category/" + id, {
    headers: authHeader(),
  });
};
const getCashLogDetailsById = (id) => {
  return axios.get(API_URL + "/cashLog/details/" + id, {
    headers: authHeader(),
  });
};

export default {
  getSummary,
  postCashLog,
  deleteCashLog,
  getCashLogsList,
  getCashLogById,
  getCashLogDetailsById,
  getCategoryById,
  getCategoriesList,
  deleteCategory,
  postCategory,
};
