import React, { useState, useEffect, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { NavLink } from "react-router-dom";
import InventoryService from "../services/inventory.service";
import EventBus from "../common/EventBus";

const currentUser = JSON.parse(localStorage.getItem('user'));
const Inventory = (props) => {
	const form = useRef();
	const checkBtn = useRef();

	const [number, setNumber] = useState("");
	const [label, setLabel] = useState("");
	const [type, setType] = useState("");
	const [description, setDescription] = useState("");
	const [branch, setBranch] = useState("");
	const [total_in_use, setTotal_in_use] = useState("");
	const [total_in_repair, setTotal_in_repair] = useState("");
	const [total_in_stock, setTotal_in_stock] = useState("");
	const [total_in_recycle, setTotal_in_recycle] = useState("");
	const [total_in_obs, setTotal_in_obs] = useState("");
	const [total_available, setTotal_available] = useState("");
	const [total, setTotal] = useState(0);
	const [available, setAvailable] = useState(0);
	const [isAccessory, setIsAccessory] = useState(false);
	const [rentPrice, setRentPrice] = useState(0);
	const [originalPrice, setOriginalPrice] = useState(0);
	const [sellPrice, setSellPrice] = useState(0);
	const [currency, setCurrency] = useState("");
	const [VAT, setVAT] = useState("");
	const [unit, setUnit] = useState("");
	const [size, setSize] = useState("");
	const [color, setColor] = useState("");
	const [MOQ, setMOQ] = useState("");
	const [supplier, setSupplier] = useState("");
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	const [total_lost, setTotal_lost] = useState("");
	const inventoryId = props.match.params.id;


	useEffect(() => {
		window.scrollTo(0, 0);
		InventoryService.getInventoryById(inventoryId).then(
			(response) => {
				let b = response.data;
				setLabel(b.label);
				setType(b.type);
				setNumber(b.number);
				setDescription(b.description);
				setBranch(b.branch.label);
				setTotal_in_use(b.total_in_use);
				setTotal_in_repair(b.total_in_repair);
				setTotal_in_stock(b.total_in_stock);
				setTotal_in_recycle(b.total_in_recycle);
				setTotal_in_obs(b.total_in_obs);
				setTotal_available(b.total_available);
				setAvailable(parseInt(b.total_available));
				setTotal(parseInt(b.total_available) + parseInt(b.total_in_recycle) + parseInt(b.total_in_obs));
				setIsAccessory(b.isAccessory);
				setRentPrice(b.rentPrice);
				setOriginalPrice(b.originalPrice);
				setSellPrice(b.sellPrice);
				setColor(b.color);
				setSize(b.size);
				setMOQ(b.MOQ);
				setSupplier(b.supplier);
				setUnit(b.unit);
				setCurrency(b.currency);
				setVAT(b.VAT);
				setTotal_lost(b.total_lost);
			},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
				alert(_content);
				setMessage(_content);

				if (error.response && error.response.status === 401) {
					EventBus.dispatch("logout");
				}
			}
		);
	}, []);


	return (
		<div className="col-md-12">

			<table className="styled-table" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ width: "50%" }}>Inventory Specification</th>
						<th style={{ float: "right" }}>{currentUser.type == "admin" ?
							<><NavLink to={"/inventory/edit/" + inventoryId} className="text-dark ">Edit</NavLink></> :
							<></>}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Branch</td>
						<td>{branch}</td>
					</tr>
					<tr>
						<td>Number</td>
						<td>{number}</td>
					</tr>
					<tr>
						<td>Label</td>
						<td>{label}</td>
					</tr>
					<tr>
						<td>Sub Category</td>
						<td>{type}</td>
					</tr>
					<tr>
						<td>Size</td>
						<td>{size}</td>
					</tr>
					<tr>
						<td>Color</td>
						<td>{color}</td>
					</tr>
					<tr>
						<td>MOQ</td>
						<td>{MOQ}</td>
					</tr>
					<tr>
						<td>Supplier</td>
						<td>{supplier}</td>
					</tr>
					<tr>
						<td>Is Accessory?</td>
						<td>{isAccessory ? "Yes" : "No"}</td>
					</tr>

					<tr>
						<td>Currency</td>
						<td>{currency}</td>
					</tr>
					<tr>
						<td>Rent Price</td>
						<td>{rentPrice}</td>
					</tr>
					<tr>
						<td>Sell Price</td>
						<td>{sellPrice}</td>
					</tr>
					<tr>
						<td>VAT</td>
						<td>{VAT}</td>
					</tr>
					<tr>
						<td>Unit</td>
						<td>{unit}</td>
					</tr>
					<tr>
						<td>Total</td>
						<td>{total}</td>
					</tr>
					<tr>
						<td>Available</td>
						<td>{available}</td>
					</tr>
					<tr>
						<td>Total Available</td>
						<td>{total_available}</td>
					</tr>
					<tr>
						<td>Total in use</td>
						<td>{total_in_use}</td>
					</tr>
					<tr>
						<td>Total in repair</td>
						<td>{total_in_repair}</td>
					</tr>
					<tr>
						<td>Total in stock</td>
						<td>{total_in_stock}</td>
					</tr>
					<tr>
						<td>Total in recycle</td>
						<td>{total_in_recycle}</td>
					</tr>
					<tr>
						<td>Total in Obs</td>
						<td>{total_in_obs}</td>
					</tr>
					<tr>
						<td>Total Lost</td>
						<td>{total_lost}</td>
					</tr>
					<tr>
						<td>Description</td>
						<td>{description}</td>
					</tr>
				</tbody>
			</table>






		</div>
	);
};

export default Inventory;
