import React, { useState, useEffect } from "react";
import authHeader from "../services/auth-header";
import InventoryService from "../services/inventory.service";
import { getBranches, getInventoriesOptions, getInventoryByType } from "../common/getFunctions";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import Loader from "../commonComponents/Loader";
import * as labels from '../labels/en';
import Pagination from "@material-ui/lab/Pagination";
import Switch from "react-switch";
import { propTypes } from "react-bootstrap/esm/Image";
import { Input } from "@material-ui/core";
import * as options from '../labels/options_en';
import { Card, Row, Col } from "react-bootstrap";
import { blue, red } from "@material-ui/core/colors";

const currentUser = JSON.parse(localStorage.getItem('user'));
const Inventories = () => {
	const [error, setError] = useState("");
	const [inventories, setInventories] = useState([]);
	const [branches, setBranches] = useState([]);
	const [branchFilter, setBranchFilter] = useState("");
	const [labelFilter, setLabelFilter] = useState("");
	const [invType, setInvType] = useState("");
	const [onlyAccessories, setOnlyAccessories] = useState(false);
	const [loading, setLoading] = useState(true);
	const [resetMsg, setResetMsg] = useState(true);
	const [currentPage, setcurrentPage] = useState(1);
	const [itemsPerPage, setitemsPerPage] = useState(48);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [count, setCount] = useState(0);
	const pageSizes = [9, 18, 24, 30, 36, 60];
	const [listView, setListView] = useState(false);

	const listInventories = async (onlyAccessories, invType, branchFilter, page, itemsPerPage, labelFilter) => {

		let data = await getInventoriesOptions(onlyAccessories, invType, branchFilter, parseInt(page - 1) * itemsPerPage, itemsPerPage, false, labelFilter);
		setInventories(data.list);
		setTotal(data ? data.total : 0);
		setCount(Math.ceil(data.total / itemsPerPage));
		setResetMsg("");
		setLoading(false);
	}

	useEffect(() => {
		async function onReady() {
			window.scrollTo(0, 0);
			setBranches(await getBranches());
			await listInventories("", "", "", page, itemsPerPage, "");
		}

		onReady()

	}, []);


	const handlePageChange = async (event, value) => {
		setPage(value);
		await listInventories(onlyAccessories, invType, branchFilter, value, itemsPerPage, labelFilter);

	};

	const handlePageSizeChange = async (newItemsPerPage) => {

		setitemsPerPage(newItemsPerPage);
		setPage(1);
		await listInventories(onlyAccessories, invType, branchFilter, page, newItemsPerPage, labelFilter);

	};

	const triggerSearch = async () => {
		setPage(1); // return to first page
		await listInventories(onlyAccessories, invType, branchFilter, page, itemsPerPage, labelFilter);

	}
	const resetSearch = async () => {
		setBranchFilter("");
		setLabelFilter("");
		setInvType("");
		await listInventories("", "", "", page, itemsPerPage, "");
	}
	const deleteInventory = async (event, id) => {
		if (window.confirm("Are you sure you want to delete this inventory?")) {
			InventoryService.deleteInventory(id).then(
				(response) => {

					let oldList = inventories;
					var data = oldList.filter(function (obj) {
						return obj._id !== id;
					});
					setInventories(data);

				},
				(error) => {
					const _content =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setError(_content);

					if (error.response && error.response.status === 401) {
						EventBus.dispatch("logout");

					}
				}
			);
		}
	}

	const handleBranchChange = async (bid) => {
		setBranchFilter(bid);
		resetResults();
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			triggerSearch(e);
		}
	}

	const resetResults = () => {
		setTotal(0);
		setCount(0);
		setInventories([]);
		setResetMsg(labels.RESET_MSG);
	}

	const setListviewSwitch = () => {
		setListView(!listView);

	}

	return (
		<div className="container">
			<div className="floatRight ">{(currentUser.type == 'admin' || currentUser.type == 'manager') ? <NavLink to={"/inventory/new"} className="greenLink mx-0">Add Inventory</NavLink> : ""}
			</div>

			<div>
				<h3> Inventories List </h3>

				<div className="filterContainer">

					{currentUser && currentUser.type == 'admin' ?
						<>
							<Col md={2.5} style={{ width: 160 }}>
								<label className="filterLabelInput" htmlFor="branch">Branch</label>

								<select
									className="form-control filterInput"
									value={branchFilter}
									onChange={(e) => handleBranchChange(e.target.value)}

								>
									<option value="" key="0">All</option>
									{branches.map((branch, index) => <option key={index} value={branch._id}>{branch.label}</option>)}

								</select>
							</Col>
						</>
						: null}
					<Col md={2.5}>
						<label htmlFor="bikerId" className="filterLabelInput">Inventory Type</label>

						<select
							className="form-control filterInput"
							value={invType}
							onChange={(e) => setInvType(e.target.value)}

						>
							<option value="">All</option>
							{
								options.inventoryType.map((type, index) => <option key={index} value={type}>{type}</option>)
							}
						</select>
					</Col>
					<Col md={2.5}>
						<label className="filterLabelInput" htmlFor="label">Label</label>
						<input
							type="text"
							className="form-control filterInput"
							value={labelFilter}
							onKeyDown={e => handleKeyDown(e)}
							onChange={(e) => setLabelFilter(e.target.value)}
						/>
					</Col>
					<table style={{ marginTop: 35 }} border="0">
						<tr>
							<td><button onClick={(e) => triggerSearch(e)} className="btn btn-primary btn-block search-button ">Go</button></td>
							<td><button onClick={(e) => resetSearch(e)} className="btn btn-primary btn-block reset-button ">Reset</button></td>
							<td className="listViewSwitch" htmlFor="listView">List View?</td>
							<Switch className="filterInput3" onChange={setListviewSwitch} checked={listView} />
						</tr>
					</table>
				</div>
			</div>

			{error ? <header className="jumbotron"> <h3>{error}</h3>  </header> : null}
			{!error && !loading && inventories && inventories.length != 0 ?
				<div>
					<Row>
						<Col><span style={{ float: "right", marginLeft: 10 }}>{total} results</span></Col>
					</Row>
					<Row style={{ display: "flex", flexDirection: "row", alignItems: "first baseline" }}>
						{!listView && inventories.map((b) =>
							<div style={{ float: "left", marginLeft: 20 }}>
								<Card style={{ marginLeft: 0, maxWidth: 250, minHeight: 300, paddingLeft: 10, align: "top" }}>
									<Card.Body>
										<Card.Title>{b.number}</Card.Title>
										<NavLink to={"/inventory/" + b._id} className="text-dark midText"><i alt="View" title="View" className="fa fa-binoculars" ></i></NavLink>&nbsp;&nbsp;&nbsp;<NavLink to={"/inventory/edit/" + b._id} className="text-dark midText"><i alt="Edit" title="Edit" className="fa fa-pen" ></i></NavLink>&nbsp;&nbsp;&nbsp;<NavLink to={"/inventory/clone/" + b._id} className="text-dark midText"><i alt="Clone" title="Clone" className="fa fa-clone" ></i></NavLink>&nbsp;&nbsp;&nbsp;<a href="javascript:void(0)" className="text-dark midText" onClick={(e) => { deleteInventory(e, b._id) }} ><i alt="Delete" title="Delete" className="fa fa-trash" ></i></a><br />
										<span className="tinyCardLabel">Label:&nbsp;</span><span className="midText">{b.label}</span><br />
										<span className="tinyCardLabel">Sub Category:&nbsp;</span><span className="midText">{b.type}</span><br />
										<span className="tinyCardLabel">Is Accessory:&nbsp;</span><span className="midText">{b.isAccessory ? "Yes" : "No"}</span><br />
										{currentUser.type === 'admin' ? <><span className="tinyCardLabel">Branch:&nbsp;</span><span className="midText">{b.branch ? b.branch.label : ""}</span><br /></> : ""}
										<span className="tinyCardLabel">Available:&nbsp;</span><span className="midText">{b.total_available}</span><br />
										<span className="tinyCardLabel">In-use:&nbsp;</span><span className="midText">{b.total_in_use}</span><br />
										<span className="tinyCardLabel">In-service:&nbsp;</span><span className="midText">{b.total_in_repair}</span><br />
										<span className="tinyCardLabel">In-stock:&nbsp;</span><span className="midText">{b.total_in_stock}</span><br />
										<span className="tinyCardLabel">In-recyle:&nbsp;</span><span className="midText">{b.total_in_recycle}</span><br />
										<span className="tinyCardLabel">In-Obs:&nbsp;</span><span className="midText">{b.total_in_obs}</span><br />
										<span className="tinyCardLabel">Lost:&nbsp;</span><span className="midText">{b.total_lost}</span><br />
									</Card.Body>
								</Card>
							</div>
						)}
						{listView && inventories && inventories.length != 0 ?
							<div>


								<table className="styled-table2">
									<thead>
										<tr>
											<th>Number</th>
											<th>Label</th>
											<th>Sub Category</th>
											<th>Is Accessory</th>
											<th>Branch</th>
											<th>Avaliable</th>
											<th>In-use</th>
											<th>In-service</th>
											<th>In-stock</th>
											<th>In-recycle</th>
											<th>In-Obs</th>
											<th>Lost</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{inventories && inventories.length != 0 ? inventories.map((b) => (

											<tr>
												<td>{b.number}</td>
												<td>
													<span className="tinyCardLabel"></span><span className="midText">{b.label}</span></td>
												<td>
													<span className="tinyCardLabel"></span><span className="midText">{b.type}</span></td>
												<td>
													<span className="tinyCardLabel"></span><span className="midText">{b.isAccessory ? "Yes" : "No"}</span></td>
												<td>
													{currentUser.type === 'admin' ? <><span className="tinyCardLabel"></span><span className="midText">{b.branch ? b.branch.label : ""}</span></> : ""}</td>
												<td>
													<span className="tinyCardLabel"></span><span className="midText">{b.total_available}</span></td>
												<td>
													<span className="tinyCardLabel"></span><span className="midText">{b.total_in_use}</span></td>
												<td>
													<span className="tinyCardLabel"></span><span className="midText">{b.total_in_repair}</span></td>
												<td>
													<span className="tinyCardLabel"></span><span className="midText">{b.total_in_stock}</span></td>
												<td>
													<span className="tinyCardLabel"></span><span className="midText">{b.total_in_recycle}</span></td>
												<td>
													<span className="tinyCardLabel"></span><span className="midText">{b.total_in_obs}</span><br /></td>
												<td>
													<span className="tinyCardLabel"></span><span className="midText">{b.total_lost}</span><br /></td>
												<td><NavLink to={"/inventory/" + b._id} className="text-dark midText"><i alt="View" title="View" className="fa fa-binoculars" ></i></NavLink>&nbsp;&nbsp;&nbsp;<NavLink to={"/inventory/edit/" + b._id} className="text-dark midText"><i alt="Edit" title="Edit" className="fa fa-pen" ></i></NavLink>&nbsp;&nbsp;&nbsp;<NavLink to={"/inventory/clone/" + b._id} className="text-dark midText"><i alt="Clone" title="Clone" className="fa fa-clone" ></i></NavLink>&nbsp;&nbsp;&nbsp;<a href="javascript:void(0)" className="text-dark midText" onClick={(e) => { deleteInventory(e, b._id) }} ><i alt="Delete" title="Delete" className="fa fa-trash" ></i></a><br /></td>

											</tr>


										)) : <tr style={{ textAlign: "center" }}>
											<td colSpan="4">There is No data</td>
										</tr>

										}



									</tbody>
								</table>
							</div>
							: <div> </div>}
					</Row>


					<div className="mt-3">
						{"Items per Page: "}
						<select onChange={(e) => handlePageSizeChange(e.target.value)} value={itemsPerPage}>
							{pageSizes.map((size) => (
								<option key={size} value={size}>
									{size}
								</option>
							))}
						</select>

						<Pagination
							className="my-3"
							count={count}
							page={page}
							siblingtotal={1}
							boundarytotal={1}
							variant="outlined"
							color="primary"
							shape="rounded"
							onChange={handlePageChange}
							size="medium"

						/>
					</div>
				</div>
				: loading ? <Loader /> :
					resetMsg != "" ? <div className='defaultMsg'>{resetMsg}</div> :
						<div className="noResultsFound">{labels.NO_RESULTS}</div>
			}


		</div>
	);
};

export default Inventories;