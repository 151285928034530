
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import EventBus from "../common/EventBus";
import surveryService from "../services/survey.service";

const currentUser = JSON.parse(localStorage.getItem('user'));
const SurveyFormView = (props) => {
    const [type, setType] = useState("");
    const [branch, setBranch] = useState("");
    const [loaded, setLoaded] = useState(false);
    const surveyFormId = props.match.params.id;
    const [label, setLabel] = useState("");
    const [isPublic, setIsPublic] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);





    const loadSurveyForm = async () => {
        await surveryService.getUserSurveyFormDetailsById(surveyFormId).then(
            (response) => {
                let r = response.data.data;
                console.log(r);
                let f = r.form;
                if (f) {
                    setLabel(f.label);
                    setType(f.type);
                    setBranch(f.branch);
                    setIsPublic(f.public);
                    setIsDisabled(f.disabled);
                }
                setLoaded(true);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                alert(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }

    useEffect(() => {

        async function onReady() {
            window.scrollTo(0, 0);
            await loadSurveyForm();
        }
        onReady()
    }, loaded);


    return (
        <div className="col-md-12">

            <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th style={{ width: "50%" }}>Survey Form Details</th>
                        <th style={{ float: "right" }}>{currentUser.type === "admin" ?
                            <><NavLink to={"/survey/form/edit/" + surveyFormId} className="text-dark ">Edit</NavLink></> : ""}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Label</td>
                        <td>{label}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>{type}</td>
                    </tr>
                    <tr>
                        <td>Branch</td>
                        <td>{branch ? branch.label : ""}</td>
                    </tr>
                    <tr>
                        <td>Public</td>
                        <td>{isPublic ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                        <td>Disabled</td>
                        <td>{isDisabled ? "Yes" : "No"}</td>
                    </tr>


                </tbody>
            </table>










        </div>
    );
};

export default SurveyFormView;
